.places-search

  &__active-background
    @media (min-width: 992px)
      background: white
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      z-index: -1
      background: white
      border-radius: 32px

  @media (min-width: 768px) and (max-width: 991px)
    max-width: 584px
    margin: auto
    border-radius: 32px

  @media (min-width: 992px)
    display: flex
    align-items: center
    height: 64px
    border-radius: 32px
    padding: 8px
    min-width: 816px
    position: relative
    z-index: 1
    &:hover .places-search__active-background
      background: #F4F1E9

  &__form-group
    display: flex
    align-items: center
    height: 44px
    border-radius: 32px
    border: 1px solid #CCC
    background: #FFF
    width: 100%
    margin-bottom: 8px
    padding: 0 16px
    position: relative
    @media (min-width: 992px)
      height: 44px
      margin-bottom: 0
      border: none
      padding: 0 16px
      position: unset
      background: transparent
      &.types, &.guests-number
        max-width: 240px
      &:hover
        box-shadow: 0px 0px 1px 0px rgba(20, 20, 20, 0.04), 0px 0px 8px 0px rgba(20, 20, 20, 0.08)
        background: white
      &.regions, &.guests-number
        position: relative
        &::before
          content: ''
          position: absolute
          height: 32px
          width: 1px
          background: rgba(156, 156, 156, 0.30)
          left: 0
      &.regions
        &.open, &:hover
          & ~ .guests-number:before
            display: none
      &.types
        &.open, &:hover
          & ~ .regions:before
            display: none
      &.guests-number, &.regions
        &.open, &:hover
          &:before
            display: none
      &.open ~ .places-search__active-background
        background: #F4F1E9

    &.open
      border-color: #444
      background: white

    &.types .basic-dropdown,
    &.guests-number .number-dropdown
      &__option
        border-radius: 16px
        height: 41px
        border-radius: 32px
        border: 1px solid #CCC
        color: #000000
        margin-bottom: 8px
        svg
          fill: #E8753D
        &:hover
          border-color: #E8753D
          background: #E8753D
          color: white
          svg
            fill: white
      &__trigger_arrow
        display: none
      &__trigger
        justify-content: flex-start
        width: 100%
      &__trigger_icon
        fill: #E8753D
        margin-right: 8px
        pointer-events: none
      &__body
        border-radius: 24px
        border: 1px solid #444
      &__body-title
        font-family: Poppins400, sans-serif
        font-size: 14px
        color: #5A6369
        line-height: 18px
        margin-bottom: 16px
      &__label
        color: #000

    &.types .basic-dropdown
      &__body
        padding: 18px 16px 16px 16px
        @media (min-width: 992px)
          max-width: 316px
          top: 72px

    &.guests-number
      .number-dropdown
        width: 100%
        height: 100%
        &__value, &__label
          font-family: Poppins400, sans-serif
          font-size: 16px
          line-height: 16px
          color: #6C7072
          &.active
            color: #000000
        &__body
          padding: 18px 24px 16px 24px
          @media (min-width: 768px) and (max-width: 991px)
            padding: 18px 16px 24px 16px

  &__submit-button
    font-family: Poppins600, sans-serif
    height: 44px
    display: flex
    justify-content: center
    align-items: center
    color: #FFF
    font-size: 16px
    line-height: 16px
    width: 100%
    border-radius: 32px
    background: #E8753D
    box-shadow: 0px 0px 8px 0px rgba(20, 20, 20, 0.08), 0px 0px 1px 0px rgba(20, 20, 20, 0.04)
    cursor: pointer
    text-decoration: none
    margin-top: 16px
    &:hover
      background: #D15E26
    svg
      margin-right: 8px
      fill: white
    @media (min-width: 992px)
      height: 48px
      width: 48px
      min-width: 48px
      border-radius: 40px
      margin-top: 0
      margin-left: 16px
      span
        display: none
      svg
        margin: 0
        height: 24px
        width: 24px

  &__search-results-list
    margin-bottom: 12px
    @media (min-width: 992px)
      margin-bottom: 24px
    &:last-child
      margin-bottom: 0

  &__search-results-list-headline
    font-family: Poppins400, sans-serif
    font-size: 14px
    line-height: 16px
    padding-bottom: 12px
    font-size: 14px
    line-height: 18px
    color: #5A6369

  &__search-results-item-label
    &.district
      text-transform: capitalize

  &__search-results-item-count
    color: #6C7072

  input
    height: 100%
    width: 100%
    border: none

  input,
  .basic-dropdown__trigger
    font-family: Poppins400, sans-serif
    font-size: 16px
    line-height: 16px

  input,
  input::placeholder,
  .dropdown__option,
  .basic-dropdown__option,
  .basic-dropdown__trigger
    color: #6C7072

  .dropdown__body,
  .basic-dropdown__body,
  .number-dropdown__body
    top: 46px
    border: 1px solid #444
    width: calc(100% + 2px)
    left: -1px
    border-radius: 16px
    @media (min-width: 992px)
      top: 62px
      border: none !important

  .basic-dropdown
    &__value svg
      fill: #E8753D
    &__body
      padding: 8px 0
    &__option
      font-family: Poppins400, sans-serif
      height: 32px
      display: flex
      align-items: center
      padding: 0 16px
      font-size: 16px
      line-height: 24px
      letter-spacing: -0.16px

  .dropdown
    &__body
      padding: 18px 16px 24px 16px
      width: calc(100% + 2px)
      @media (min-width: 992px)
        width: 568px
        padding: 18px 24px 24px 24px
    &__options-wrapper
      columns: 2
      column-gap: 8px
      margin-bottom: -8px
      @media (min-width: 768px)
        column-gap: 16px
    &__option
      font-family: Poppins400, sans-serif
      height: 33px
      border-radius: 32px
      border: 1px solid #CCC
      font-size: 13px
      line-height: 16px
      margin-bottom: 8px
      display: flex
      align-items: center
      justify-content: center
      color: #000
      width: 100%
      &:hover
        background: #E8753D
        border-color: #E8753D
        color: #FFF
      &:nth-child(odd)
        margin-right: 8px
      @media (min-width: 768px)
        height: 37px
        font-size: 16px
        line-height: 20px
      &.places-search__search-results-list-item
        border-radius: 32px
        border: 1px solid #CCC
        justify-content: space-between
        margin-bottom: 0
        padding: 0 24px
        font-size: 16px
        color: #000000
        margin-bottom: 8px
        height: 37px
        line-height: 20px
        &:last-child
          margin-bottom: 0
        &:hover
          background: #E8753D
          border-color: #E8753D
          color: #FFF
          .places-search__search-results-item-count
            color: #FFF
    &__no-results
      font-family: Poppins500, sans-serif
      font-size: 16px
      line-height: 20px
      color: #E8753D
      margin-bottom: 24px
      display: flex
      align-items: center
      position: relative
      padding-left: 28px
      margin-top: 8px
      @media (min-width: 768px)
        margin-bottom: 20px
      .info-tooltip-wrapper
        margin-right: 8px
        height: 30px
        width: 30px
        position: absolute
        top: -5px
        left: -5px
        z-index: 999
      svg
        fill: #E8753D
        cursor: pointer
        margin-right: 0
        &:hover
          fill: #D15E26
    &__body-title
      font-family: Poppins400, sans-serif
      font-size: 14px
      color: #5A6369
      line-height: 18px
      margin-bottom: 16px
    &__trigger
      width: 100%
    &__search-input
      color: #000
