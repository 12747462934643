.dropdown
  height: 100%
  width: 100%
  cursor: pointer

  &__trigger
    height: 100%
    display: flex
    align-items: center

  &__body
    position: absolute
    background: #FFF
    width: 100%
    left: 0
    z-index: 10
    cursor: default

  &__option
    cursor: pointer

  svg
    margin-right: 8px
    fill: #E8753D
    min-width: 16px

  input
    border: none
    width: 100%
    background: transparent
