.number-dropdown

  &__trigger
    height: 100%
    display: flex
    align-items: center
    cursor: pointer
    overflow: hidden

  &__trigger_icon
    margin-right: 8px

  &__label
    margin-left: 5px

  &__body
    position: absolute
    background: white
    input
      height: 100%
      border-radius: 32px
      border: 1px solid #CCC !important
      padding: 8px 16px
      margin: 0 8px
      width: 100%
      color: #000 !important
    &:not(input)
      user-select: none

  &__body-input-wrapper
    display: flex
    align-items: center
    width: 100%
    margin-bottom: 24px

  &__body-input-control
    width: 44px
    height: 44px
    min-width: 44px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 32px
    border: 1px solid #5A6369
    cursor: pointer
    color: #000
    &.inactive
      border-color: #CCC
      cursor: default
      color: #6C7072

  &__body-buttons
    width: 100%
    border-top: 1px solid rgba(204, 204, 204, 0.40)
    padding-top: 12px
    display: flex
    align-items: center
    justify-content: space-between

  &__body-clear-btn,
  &__body-save-btn
    font-family: Poppins500, sans-serif
    cursor: pointer
    line-height: 19px
    font-size: 14px
    height: 44px
    width: 50%
    display: flex
    justify-content: center
    align-items: center
    &.inactive
      pointer-events: none

  &__body-clear-btn
    color: #5A6369
    &:hover
      text-decoration: underline
      color: #000

  &__body-save-btn
    padding: 12px
    color: white
    border-radius: 32px
    background: #E8753D
    &:hover
      backgroun: #D15E26

  &:not(.consistent)
    @media (min-width: 768px) and (max-width: 991px)
      .number-dropdown
        &__body-input-wrapper
          margin-bottom: 0
        &__body-content
          display: flex
          align-items: center
        &__body-buttons
          padding-top: 0
          border-top: none
          margin-left: 16px
          width: auto
        &__body-clear-btn
          width: 101px
        &__body-save-btn
          width: 130px
