.basic-dropdown
  height: 100%
  width: 100%
  cursor: pointer
  //position: relative

  &__trigger
    height: 100%
    display: flex
    align-items: center
    justify-content: space-between
    color: black
    position: relative
    user-select: none

  &__trigger_arrow
    pointer-events: none
    fill: #6C7072
    position: absolute
    right: 0

  &__body
    position: absolute
    background: #FFF
    width: 100%
    left: 0
    z-index: 10
    cursor: default

  &__option
    cursor: pointer
    svg
      margin-right: 8px

  &__value
    display: flex
    align-items: center
    pointer-events: none
    user-select: none
    svg
      margin-right: 8px


