.topbar
  background: #f5f4c8
  position: sticky
  top: 0
  left: 0
  z-index: 120
  background: rgba(255,252,243, 0.7)
  backdrop-filter: saturate(180%) blur(10px)

  &__container
    display: flex
    width: 100%
    align-items: center
    justify-content: space-between
    padding: 1rem

  &__logo
    width: 100%
    height: auto

  &__mobile-menu-toggle-wrapper
    display: flex
    align-items: center

  &__search-toggle
    cursor: pointer
    height: 32px
    width: 32px
    margin-left: 24px
    border-radius: 50%
    border: 1px solid #444
    display: flex
    align-items: center
    justify-content: center
    @media (min-width: 992px)
      margin-left: 8px
    svg
      fill: #444
      pointer-events: none
    &:hover
      border-color: #E8753D
      svg
        fill: #E8753D

  &__burger-wrapper
    position: relative
    height: 16px

  &__menu-toggle
    cursor: pointer
    height: 32px
    width: 32px
    margin-left: 16px
    border-radius: 50%
    border: 1px solid #444
    display: flex
    align-items: center
    justify-content: center
    @media (min-width: 992px)
      display: none

    span
      border-radius: 5px
      width: 14px
      height: 2px
      display: block
      position: absolute
      left: calc(50% - 7px)
      background: #444
      transition: transform 0.15s linear
      &:nth-child(1)
        top: 2px
      &:nth-child(2), &:nth-child(3)
        will-change: transform
        top: 7px
      &:nth-child(4)
        top: 12px

    &.cross .topbar__burger-wrapper
      right: 3px
      span
        width: 20px
        &:nth-child(1), &:nth-child(4)
          top: 24px
          opacity: 0
          width: 0
          left: 30px
        &:nth-child(2)
          transform: rotate(45deg)
        &:nth-child(3)
          transform: rotate(-45deg)
    &:hover
      border-color: #E8753D
      span
        background: #E8753D

  &__menu-wrapper
    background: white
    display: block
    height: calc(100vh - 64px)
    width: 0
    position: fixed
    z-index: 120
    top: 64px
    left: 0
    right: 0
    transform: translateX(100%)
    max-height: calc(100vh - 64px)
    overflow-y: auto
    border-top: 1px solid #e8eaea
    //border-bottom: 1px solid #e8eaea
    transition: transform 0.2s linear, width 0s linear 0.5s
    min-width: 0
    scroll-behavior: smooth
    @media (max-width: 991px)
      &.shown
        transform: translateX(0)
        transition: width 0s linear, transform 0.2s linear
        min-width: 200px
        width: 100vw
        left: 0
    @media (min-width: 992px)
      &:not(.search)
        background: none
        display: flex
        height: 100%
        justify-content: space-between
        width: 100%
        position: relative
        border: none
        transform: none
        overflow-y: inherit
        top: 0
    ul
      list-style: none
      margin: 0
      padding: 0
      display: flex
      flex-direction: column
      justify-content: flex-start
      @media (min-width: 992px)
        flex-direction: row
      li
        //border-bottom: 1px solid #E0E2E2
        padding: 0 16px
        position: relative
        overflow: hidden
        height: 50px
        transition: ease 600ms
        display: flex
        align-items: center
        @media (min-width: 992px)
          border: none
          padding: 0 12px

    &.search
      overflow: hidden
      .places-search
        padding: 32px 16px
      .places-search__form-group
        margin-bottom: 16px
      @media (min-width: 992px)
        overflow: unset
        height: auto
        margin-top: 40px
        border-top: none
        background: none
        left: 100vw
        &.shown
          transform: translateX(0)
          transition: width 0s linear, transform 0.2s linear
          min-width: 200px
          width: 100vw
          left: 0
        .places-search
          max-width: 900px
          margin: 0 auto
          filter: drop-shadow(rgba(0, 0, 0, 0.25) 0 6px 6px)
          &__form-group
            margin-bottom: 0
            &.types
              max-width: unset
          .basic-dropdown__body, .dropdown__body, .number-dropdown__body
            filter: drop-shadow(rgba(0, 0, 0, 0.25) 0 6px 6px)

  &__menu-left
    @media (min-width: 992px)
      display: flex
      flex-direction: row-reverse

  &__menu-left-list
    font-family: Poppins500, sans-serif
    list-style: none
    display: flex
    flex-flow: row nowrap
    li
      padding: 18px 10px
      a
        display: flex
        align-items: flex-start
        color: #444
        text-decoration: none
        white-space: nowrap
        svg
          margin-left: 8px
        &:hover
          color: #E8753D
